import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { OutsideClick } from "outsideclick-react";
import { Image } from 'components/Image/Image';
import circleProfile from 'assets/img/icons/circle_profile.png';
import { connect } from 'react-redux';
import NavBar from 'components/Navigations/NavBar/NavBar';
import { pushToastMessage } from 'store/actions/root/toast';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import { fetchCurrentUserData, receiveCurrentUserData } from 'store/actions/root/user';
import { addCompanyList, setRequestStatus, clearCompanyList, updateSelectedCompanies, addAttributes } from 'store/actions/user/companies';
import CircleCheckbox from 'components/form/CircleCheckbox/CircleCheckbox';
import ScrollToTop from 'components/Commons/ScrollToTop/ScrollToTop';
import './Research.scss';

function mdToAnchor(md) {
  const rxCommonMarkLink = /(\[([^\]]+)])\(([^)]+)\)/g;
  const anchor = md
    ? md.replace(rxCommonMarkLink, '<a target="_blank" href="$3">$2</a>')
    : md
    ;
  return anchor;
}

export class Research extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chat: [],
      images: {},
      followUpQuestions: ["What is the market size of solid oxide fuel cells?",
        "What are the market drivers for carbon capture and utilization?",
        "How does a fuel cell work?",
        "What partnerships has Shell established about carbon capture?",
        "What are the methane regulations in the US?"],
      message: "",
      typing: false,
      expandingTyping: false,
      streaming: false,
      stop: false,
      autocomplete: false,
      showMoreClicked: false,
      diveDeeperClicked: false,
      imagesLoading: false,
      myDataCheckBox : {
        id: 'useMyReportsData',
        text: 'Use Enki Reports Data',
        value: false,
        color: 'blue',
      },
      liteDataCheckBox : {
        id: 'liteData',
        text: 'Use On-Demand Data',
        value: true,
        color: 'blue',
      },
      modelDataCheckBox : {
        id: 'modelData',
        text: 'Use Idea Generation Data',
        value: false,
        color: 'blue',
      },
      longReportCheckBox : {
        id: 'longReport',
        text: 'Write Creative And Long',
        value: false,
        color: 'blue',
      },
      shortReportCheckBox : {
        id: 'shortReport',
        text: 'Write Precise And Short',
        value: true,
        color: 'blue',
      },
      customReportCheckBox : {
        id: 'customReport',
        text: 'Generate A Custom Output For Idea Generation',
        value: false,
        color: 'blue',
      },
      myDataDate : '2020-01-01',
      liteDataDate: '2020-01-01'
    }
    this.chatRef = React.createRef();
  }

  componentWillMount() {
    this.props.fetchCurrentUserData();
  }

  getAnswer = async (message, chat, expandResearch, showMore, retry) => {
    if (retry == 2){
      this.setState({
        typing: false,
        expandingTyping: false,
        chat: [...this.state.chat, { role: "assistant", content: "Sorry, I don't have enough data to answer."}]
      })
      return;
    }

    this.controller = new AbortController();
    this.setState({streaming: true});
    this.addImages(chat);

    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      chat: chat,
      myReportsData: this.state.myDataCheckBox.value,
      liteData: this.state.liteDataCheckBox.value,
      modelData: this.state.modelDataCheckBox.value,
      longReport: this.state.longReportCheckBox.value,
      shortReport: this.state.shortReportCheckBox.value,
      customReport: this.state.customReportCheckBox.value,
      myDataDate: this.state.myDataDate,
      liteDataDate: this.state.liteDataDate,
      expandResearch: expandResearch,
      showMore: showMore
    };
    try {
      const response = await fetch(`${host}/api/nlp/chatStream`, {
        method: 'POST',
        signal: this.controller.signal,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
      })
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
      while (true) {
        const { value, done } = await reader.read();
        if (done || this.state.stop) break;

        const message = value
        const messages = this.state.chat;
        const recentMessage = messages[this.state.chat.length - 1];

        if (recentMessage.role === "assistant") {
          if ((expandResearch || showMore) && recentMessage.content.toLowerCase().includes("sorry")){
            const previous = recentMessage.content.toLowerCase().split("sorry")[0].lastIndexOf("\n");
            recentMessage.content = (previous != -1 ? recentMessage.content.slice(0,previous) : "I expanded my search, but I could not find anything. Please contact erhan@enkiai.com to run a deep Enki search.");
            this.setState({ chat: messages });
            break;
          }else if(retry > 0  && recentMessage.content.toLowerCase().includes("sorry")){
            const previous = recentMessage.content.toLowerCase().split("sorry")[0].lastIndexOf("\n");
            recentMessage.content = (previous != -1 ? recentMessage.content.slice(0,previous) : "Sorry, I don’t have enough data to answer.");
            this.setState({ chat: messages });
            break;
          }else if(recentMessage.content.toLowerCase().includes("sorry")){
            throw 'Message includes sorry.';
          }else{
            recentMessage.content += message;
            recentMessage.content = mdToAnchor(recentMessage.content);
            this.setState({ chat: messages })
          }
        } else {
          this.setState({
            typing: false,
            expandingTyping: false,
            chat: [...this.state.chat, { role: "assistant", content: mdToAnchor(message) }]
          })
        }
      }

      // Disable this feature for now
      // const lastMessage = this.state.chat[this.state.chat.length - 1];
      // if(!lastMessage.content.includes("<a")){
      //   throw 'Response does not contain link.';
      // }
      // if(lastMessage.content.includes("knowledge cutoff date")){
      //   throw 'Response contains stop words.';
      // }

      this.setState({streaming: false, stop: false});
      this.generateFollowUpQuestions();

    } catch (e){
      if (e instanceof DOMException && e.name == "AbortError"){
        this.setState({streaming: false, stop: false});
        return
      }

      const messages = this.state.chat;
      const recentMessage = messages[this.state.chat.length - 1];

      if (recentMessage.role === "assistant")
        this.setState({ typing: true, chat: messages.slice(0, -1) });
      else
        this.setState({ typing: true });

      await delay(1000);
      this.getAnswer(message, chat, expandResearch, showMore, retry + 1);
    }
  }

  generateFollowUpQuestions = () => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';

    fetch(`${host}/api/nlp/followUp`, {
      method: 'POST',
      signal: this.controller.signal,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ chat: this.state.chat.slice(-2) })
    }).then(response => response.json())
      .then(questions => {
        if (questions.length > 0)
          this.setState({ followUpQuestions: questions });
        else
          this.setState({ followUpQuestions: ["There is a problem getting follow up questions, click here to try again."] });
      }).catch(e => {
        if (e instanceof DOMException && e.name == "AbortError"){
          this.setState({streaming: false, stop: false});
          return
        }

        console.error('Error generating follow-up questions', e);
        this.setState({ followUpQuestions: ["There is a problem getting follow up questions, click here to try again."] });
      });
  }

  addImages = (chat) => {
    this.setState({ imagesLoading: true });
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const idx = chat.length;

    fetch(`${host}/api/nlp/images`, {
      method: 'POST',
      signal: this.controller.signal,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ chat: chat.slice(-2), liteData: this.state.liteDataCheckBox.value })
    }).then(response => response.text())
      .then(data => {
        this.setState({
          imagesLoading: false,
          images: {
            ...this.state.images,
            [idx]: data
          }
        })
      }).catch(e => {
        this.setState({ imagesLoading: false });
        console.error('Error generating chart', e);
      });
  }

  onKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (this.state.streaming) {
        this.onStopClick()
      } else {
        this.onClick()
      }
    }
  }

  onClick = () => {
    const message = this.state.message;
    const chat = [...this.state.chat, { role: "user", content: message }];
    this.setState({ typing: true, chat: chat, followUpQuestions: [], autocomplete: false, showMoreClicked: false, diveDeeperClicked: false })
    this.getAnswer(message, chat, false, false, 0)
    this.setState({ message: "" })
  }

  onStopClick = () => {
    this.setState({ stop: true, typing: false, expandingTyping: false, streaming: false, expandResearch: false });
    this.controller.abort();
  }

  onFollowUpClick = (e) => {
    const message = e.target.textContent;
    if (message == "There is a problem getting follow up questions, click here to try again."){
      this.setState({ message: "", followUpQuestions: [] });
      this.generateFollowUpQuestions();
    }else{
      this.setState({ message: message }, () => {
        this.onClick()
      });
    }
  }

  onExpandResearchClick = researchType => (e) => {
    this.controller.abort();
    const message = this.state.chat.slice(-2).content;
    this.setState({ typing: true, expandingTyping: true, followUpQuestions: [], chat: this.state.chat.slice(0, -1) }, () => {
      if(researchType == "diveDeeper"){
        this.setState({ diveDeeperClicked: true });
        this.getAnswer(message, this.state.chat, true, false, 0);
      }else if (researchType == "showMore"){
        this.setState({ showMoreClicked: true });
        this.getAnswer(message, this.state.chat, false, true, 0);
      }
    });
  }

  onInputChange = (e) => {
    this.setState({ message: e.target.value, autocomplete: true });
  }

  onDataCheckBoxChange = (checkbox) => {
    return (e) => {
      const { myDataCheckBox, liteDataCheckBox, modelDataCheckBox } = this.state;

      myDataCheckBox.value = false;
      liteDataCheckBox.value = false;
      modelDataCheckBox.value = false;
      checkbox.value = true;

      this.setState({ myDataCheckBox, liteDataCheckBox, modelDataCheckBox });
    }
  }

  onLengthCheckBoxChange = (checkbox) => {
    return (e) => {
      const { longReportCheckBox, shortReportCheckBox, customReportCheckBox } = this.state;

      longReportCheckBox.value = false;
      shortReportCheckBox.value = false;
      customReportCheckBox.value = false;
      checkbox.value = true;

      this.setState({ longReportCheckBox, shortReportCheckBox, customReportCheckBox });
    }
  }

  onChangeDate = (state) => ({ target }) => {
    this.setState({ [state]: target.value });
  }

  onOutsideClick = (e) => {
    this.setState({ autocomplete: false });
  }

  onAutocompleteClick = (e) => {
    const message = e.target.textContent;
    this.setState({ message: message });
  }

  componentDidUpdate() {
    // this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
  }

  render() {
    const { chat, images, imagesLoading, followUpQuestions, typing, expandingTyping, streaming, autocomplete, myDataCheckBox,
      liteDataCheckBox, longReportCheckBox, shortReportCheckBox, myDataDate, liteDataDate, showMoreClicked, diveDeeperClicked } = this.state;
    const { mobileNavigationStatus, currentUserData = {} } = this.props;
    const { userType = 'demo' } = currentUserData;
    const autocompleteSuggestions = [...new Set(chat
      .filter(i => i.role == "user" && i.content.startsWith(this.state.message) && i.content.length > this.state.message.length)
      .map(i => i.content.trim())
    )];

    return (
      <div className={`margin-t-0 sidenav ${mobileNavigationStatus ? 'open' : ''}`}>
        <NavBar theme="dark" logo={{ small: true, desktop: true }} isDemo={(userType === 'demo')} withoutNavigation={false} iconMenuItems={[]} />
        <div className="research">
          <div className="research__form">
            {chat.length === 0
              ? <div>
                <div className="research__head__title">Ask Enki to research your ideas</div>
                <div className="research__head__subtitle">Start your research by asking any questions about your problem, project, or goal.</div>
              </div> : ""}

            <div className="research__form__header">
              <div className='research__form__checkboxes'>
                <div className='research__form__checkbox'>
                  <CircleCheckbox {...liteDataCheckBox} handleChange={this.onDataCheckBoxChange(liteDataCheckBox)} />
                  {<input type="date" value={liteDataDate} min="2000-01-01" onChange={this.onChangeDate("liteDataDate")} />}
                </div>
                <div className='research__form__checkbox'>
                  <CircleCheckbox {...myDataCheckBox} handleChange={this.onDataCheckBoxChange(myDataCheckBox)} />
                  {<input type="date" value={myDataDate} min="2000-01-01" onChange={this.onChangeDate("myDataDate")} />}
                </div>
              </div>
              <div className='research__form__checkboxes'>
                <div className='research__form__checkbox'><CircleCheckbox {...shortReportCheckBox} handleChange={this.onLengthCheckBoxChange(shortReportCheckBox)} /></div>
                <div className='research__form__checkbox'><CircleCheckbox {...longReportCheckBox} handleChange={this.onLengthCheckBoxChange(longReportCheckBox)} /></div>
              </div>
            </div>

            <div class="research__form__history-container" ref={this.chatRef}>
              {chat.map((msg, idx) =>
                msg.role === "user" ? <div className={"research__form__" + msg.role} >
                  <div className="research__form__image"><Image src={circleProfile} /></div>
                  <div className="research__form__speaker">You</div>
                  <div className="research__form__message" dangerouslySetInnerHTML={{ __html: msg.content }}></div>
                </div> : <div className={"research__form__" + msg.role} >
                  <div className="research__form__image"><Image src={circleProfile} /></div>
                  <div className="research__form__speaker">ENKI</div>
                  <div className="research__form__message" dangerouslySetInnerHTML={{ __html: msg.content }}></div>
                  {idx in images && <div className="research__form__message" dangerouslySetInnerHTML={{ __html: images[idx] }}></div>}
                </div>
              )}

              {streaming === false && imagesLoading === true &&
                <div className="research__form__message">
                  <div class="typing-message"> Give me a few mins… I’m expanding the search </div>
                  <div class="typing">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }

              {typing === true ? <div className={"research__form__assistant"} >
                <div className="research__form__image"><Image src={circleProfile} /></div>
                <div className="research__form__speaker">ENKI</div>
                <div className="research__form__message">
                  {expandingTyping === true ?
                    <div class="typing-message">
                      Give me a few mins… I’m expanding the search
                    </div> : ""}
                  <div class="typing">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div> : ""}

              <div className={"research__form__expand"}>
                {chat.length > 0 && !typing && !streaming && !showMoreClicked && !imagesLoading ? <button type="button" onClick={this.onExpandResearchClick("showMore")}>Show more</button> : ""}
                {/* {chat.length > 0 && !typing && !streaming && !diveDeeperClicked && !myDataCheckBox.value ? <button type="button" onClick={this.onExpandResearchClick("diveDeeper")}>Dive deeper</button> : ""} */}
              </div>

              {followUpQuestions.length === 0
                ? ""
                : followUpQuestions.map((question) => <div className="research__form__followup" onClick={this.onFollowUpClick}>{question}</div>)}
            </div>

            <div class="research__form__input-container">
              {autocomplete && autocompleteSuggestions.length > 0 ? <OutsideClick onOutsideClick={this.onOutsideClick}>
                <div className='research__form__autocomplete-box'>{autocompleteSuggestions.map(i => <div className="research__form__autocomplete" onClick={this.onAutocompleteClick}>{i}</div>)}</div>
              </OutsideClick> : ""}

              {typing || streaming ?
                <div class="research__form__icon" onClick={this.onStopClick} > <i class="material-icons">stop_circle</i></div> :
                <div class="research__form__icon" onClick={this.onClick} > <i class="material-icons">send</i></div>
              }

              <TextareaAutosize
                rows="1"
                autoFocus
                className="research__form__input"
                onChange={this.onInputChange}
                onKeyPress={this.onKeyUp}
                value={this.state.message}
                onClick={this.onInputChange}
              />
            </div>

          </div>
        </div>
        <ScrollToTop />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
    pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
    receiveCurrentUserData: (params) => dispatch(receiveCurrentUserData(params)),
    updateMessagePopup: (data) => dispatch(updateMessagePopup(data)),
    setRequestStatus: (status) => dispatch(setRequestStatus(status)),
  }
};

const mapStateToProps = function (store) {
  const { rootReducers, userReducers } = store;
  const { userDataReducers } = rootReducers;
  const { mobileNavigationReducers } = userReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
    mobileNavigationStatus: mobileNavigationReducers.status
  };
};

const delay = ms => new Promise(res => setTimeout(res, ms));

export default connect(mapStateToProps, mapDispatchToProps)(Research);
